
<template>

      <aside class="sidebar position-fixed">
        <div class="logo position-relative">
          <p class="text-center mb-0 text-uppercase">Tariq Traders</p>
        </div>
        <div class="sidelinks-wrapper" v-if="user_role==='computer_operator'">
          <ul class="list-unstyled p-0" >
            <li class="d-flex flex-wrap align-items-center custom-collapse">
                <a href="#" class="position-relative d-flex  align-items-center w-100" @click.prevent="arrowDown($event)">
                  <span class="d-flex  justify-content-center align-items-center icon-box">
                    <i class="bi bi-file-spreadsheet"></i>
                  </span>
                  <p class="mb-0">Balance Sheets</p>
                  <i class="caret ms-auto me-0"></i>
                </a>
                <ul class="side-collapse submenu w-100 p-0">
                  <li>
                    <router-link to="/balancesheet/shop" class="d-flex flex-wrap align-items-center">
                      <i class="character position-relative text-uppercase fst-normal fw-normal text-center">s</i>
                      <p class="mb-0">Shop</p>
                    </router-link>
                  </li>
                  <!--<li>
                    <router-link to="/balancesheet/salesman" class="d-flex flex-wrap align-items-center">
                      <i class="character position-relative text-uppercase fst-normal fw-normal text-center">s</i>
                      <p class="mb-0">Salesman</p>
                    </router-link>
                  </li>-->
                  <li>

                    <router-link to="/balancesheet/daily" class="d-flex flex-wrap align-items-center">
                      <i class="character position-relative text-uppercase fst-normal fw-normal text-center">dc</i>
                      <p class="mb-0">Daily Check</p>
                    </router-link>
                  </li>
                </ul>
            </li>
            <li>
              <router-link to="/billing" class="d-flex flex-wrap align-items-center">
                <i class="bi bi-cash-stack position-relative"></i>
                <p class="mb-0">Billings</p>
              </router-link>
            </li>
            <li>
              <router-link to="/return" class="d-flex flex-wrap align-items-center">
                <i class="bi bi-cash-stack position-relative"></i>
                <p class="mb-0">Returns</p>
              </router-link>
            </li>
            <li>
              <router-link to="/companies" class="d-flex flex-wrap align-items-center">
               <i class="bi bi-building position-relative"></i>
                <p class="mb-0">Companies</p>
              </router-link>
            </li>
            <li class="d-flex flex-wrap align-items-center custom-collapse">
                <a href="#" class="position-relative d-flex  align-items-center w-100" @click.prevent="arrowDown($event)">
                  <span class="d-flex  justify-content-center align-items-center icon-box">
                    <i class="bi bi-house-door"></i>
                  </span>
                  <p class="mb-0">Inventory</p>
                  <i class="caret ms-auto me-0"></i>
                </a>
                <ul class="side-collapse submenu w-100 p-0">
                  <li>
                    <router-link to="/inventory-daily" class="d-flex flex-wrap align-items-center">
                      <i class="character position-relative text-uppercase fst-normal fw-normal text-center">d</i>
                      <p class="mb-0">Daily</p>
                    </router-link>
                  </li>
                  <!--<li>
                    <router-link to="/" class="d-flex flex-wrap align-items-center">
                      <i class="character position-relative text-uppercase fst-normal fw-normal text-center">dt</i>
                      <p class="mb-0">Daily Total</p>
                    </router-link>
                  </li>-->
                </ul>
            </li>
            <li>
              <router-link to="/salesman" class="d-flex flex-wrap align-items-center">
                <i class="bi bi-briefcase position-relative"></i>
                <p class="mb-0">Salesman</p>
              </router-link>
            </li>
            <li>
              <router-link to="/shops" class="d-flex flex-wrap align-items-center">
                <i class="bi bi-shop position-relative"></i>
                <p class="mb-0">Shopkeepers</p>
              </router-link>
            </li>
            
          </ul>
          
        </div>

        <div class="sidelinks-wrapper" v-else-if="user_role==='super_admin'">
          <ul class="list-unstyled p-0" >
            
            <li class="d-flex flex-wrap align-items-center custom-collapse">
                <a href="#" class="position-relative d-flex  align-items-center w-100" @click.prevent="arrowDown($event)">
                  <span class="d-flex  justify-content-center align-items-center icon-box">
                    <i class="bi bi-house-door"></i>
                  </span>
                  <p class="mb-0">Inventory</p>
                  <i class="caret ms-auto me-0"></i>
                </a>
                <ul class="side-collapse submenu w-100 p-0">
                  <li>
                    <router-link to="/inventory-daily" class="d-flex flex-wrap align-items-center">
                      <i class="character position-relative text-uppercase fst-normal fw-normal text-center">d</i>
                      <p class="mb-0">Daily</p>
                    </router-link>
                  </li>
                </ul>
            </li>
            <li>
              <router-link to="/companies" class="d-flex flex-wrap align-items-center">
               <i class="bi bi-building position-relative"></i>
                <p class="mb-0">Companies</p>
              </router-link>
            </li>
            
          </ul>
          
        </div>

      </aside>
</template>
<script>
import '@/assets/scss/layout/_sidebar.scss';
import {computed, watch, ref} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default{
  name: 'Sidebar',
  setup(){
    const dropdown=ref(false);
    const store=useStore();
    const user_role=ref('');
    const router= useRouter();
    
    


    watch(async () => {
      try {
        const user = computed(() => store.state.Auth.user);
        user_role.value=user.value.role.name;
        
        console.log(user_role.value);
      } catch (e) {
        await router.push('/');
      }
    });
      
    function arrowDown(event) {
      const active = document.querySelectorAll('.show');
        active.forEach(node => {
          if(node != event.currentTarget.parentNode) {
            node.classList.remove("show");
          }
        });
        event.currentTarget.parentNode.classList.toggle("show");
        
      }

    return {
      dropdown,
      arrowDown,
      user_role
    }
  }
}
</script>

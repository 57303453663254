<template>
    <!-- Edit Modal -->
    <div
        class="modal fade p-0"
        id="addGenerate"
        tabindex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-md">
            <div class="d-flex flex-wrap w-100 position-relative event-auto">
                <div class="card shadow-none">
                    <div
                        class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
                    >
                        <div class="content-card-left w-100">
                            <h4 class="card-title text-white fs-4">Shop BalanceSheet</h4>
                            <p class="fw-light text-white mb-0 pt-1 ">
                                Generate PDF based on options
                            </p>
                        </div>
                    </div>
                    <div class="card-body">
                        <Form
                            @submit="onSubmit"
                            :validation-schema="schema"
                            class="custom-form mt-2"
                            ref="myForm"
                        >
                            <div class="position-relative form-group">
                                <Field
                                    as="select"
                                    name="salesman_id"
                                    class="form-select custom-select"
                                    @click="selectFocus = true"
                                    v-model="salesman_id"
                                >
                                    <option value="0">Choose Salesman</option>
                                    <option
                                        v-for="salesman in salesmen"
                                        :value="salesman.id"
                                        :key="salesman.id"
                                        >{{
                                            salesman.first_name + ' ' + salesman.last_name
                                        }}</option
                                    >
                                </Field>
                                <label
                                    :class="[
                                        selectFocus ? 'focus' : '',
                                        'position-absolute select-label'
                                    ]"
                                    >Salesman</label
                                >
                                <i class="bi bi-caret-down-fill select-arrow position-absolute"></i>
                                <ErrorMessage
                                    class="invalid-message mb-0 pt-2"
                                    name="salesman_id"
                                />
                            </div>
                            <div class="position-relative form-group w-100">
                                <Multiselect  @close="changeMultiselectShop(value)" v-model="value"
                                :options="shopOptions" class="custom-multiselect" placeholder="Shop"
                                :searchable="true" :showOptions="true" mode="single"
                                label="shopName" trackBy = "shop_name" valueProp="id"
                                :name="shops" />
                                <Field
                                    type="hidden"
                                    id="shopid"
                                    name="shopid"
                                    v-model="values_hidden_shops"
                                />
                                <ErrorMessage class="invalid-message mb-0 pt-2" name="shopid" />
                            </div>
                            <div class=" form-group">
                                <v-date-picker
                                    :popover="{ visibility: 'click' }"
                                    class="custom-calendar left-caret-none"
                                    popover-align="right"
                                    v-model="range"
                                    is-range
                                    :columns="$screens({ default: 2, lg: 2 })"
                                >
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <div class="d-flex align-items-center ">
                                            <div
                                                class="position-relative calendar-input-wrapper clearfix w-100"
                                            >
                                                <i
                                                    class="bi bi-calendar3 position-absolute icon-left-placement icon-without-label"
                                                ></i>
                                                <input
                                                    placeholder="From"
                                                    :value="inputValue.start"
                                                    v-on="inputEvents.start"
                                                    class="border-0 icon-left position-relative float-left w-100"
                                                />
                                            </div>
                                            <span class="d-inline-block mx-3 mx-sm-4">To</span>
                                            <div
                                                class="position-relative calendar-input-wrapper clearfix w-100"
                                            >
                                                <i
                                                    class="bi bi-calendar3 position-absolute icon-left-placement icon-without-label"
                                                ></i>
                                                <input
                                                    placeholder="To"
                                                    :value="inputValue.end"
                                                    v-on="inputEvents.end"
                                                    class="border-0 icon-left position-relative float-left w-100"
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </v-date-picker>
                            </div>
                            <div
                                class="card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 mb-3 px-4 pb-0 border-0 bg-transparent"
                            >
                                <button class="btn btn-primary w-100">
                                    Generate
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <generate-pdf
        :salesman_id="salesman_id"
        :shop_id="values_hidden_shops"
        :count_number="count_number"
        :start_date="range.start"
        :end_date="range.end"
    ></generate-pdf>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
// Add this for multiselect
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
//import "@/assets/scss/components/_multiselect.scss";
import salesmanServices from '@/services/SalesmanServices';
import salesmanShopServices from '@/services/SalesmanShopServices';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import GeneratePdf from '@/components/pdf/GenerateShopBalancesheetPdf.vue';
export default {
    components: {
        Multiselect,
        Form,
        Field,
        ErrorMessage,
        GeneratePdf
    },
    setup() {
        const selectFocus = ref(false);
        const salesmen = ref([]);
        const salesman_id = ref(0);
        const count_number = ref(0);
        const values_hidden_shops = ref('');
        const range = ref('');
        const multiValue = ref('');
        const shopOptions = ref('');
        const closeModal = () => {
            const myModalEl = document.getElementById('addGenerate');
            const modal = window.bootstrap.Modal.getInstance(myModalEl);
            modal.hide();
        };
        async function changeMultiselectShop(value) {
            values_hidden_shops.value = value;
        }
        const schema = yup.object().shape({
            salesman_id: yup.number().moreThan(0, 'Salesman Required')
        });
        onMounted(async () => {
            await salesmanServices.paginateSalesman().then(response => {
                salesmen.value = response.data.data;
            });
        });
        watch(
            () => salesman_id.value,
            (currentValue, oldValue) => {
                if (currentValue != oldValue && currentValue != '') {
                    salesmanShopServices.getSalesmanShops(salesman_id.value).then(response => {
                        shopOptions.value = response.data.data;
                    });
                    //resetCustomForm();
                }
            }
        );
        const onSubmit = async (values, actions) => {
            //alert(JSON.stringify(values, null, 2));
            //alert(range.value.start);

            count_number.value += 1;
            //console.log(JSON.stringify(values, null, 2));
        };
        return {
            selectFocus,
            closeModal,
            shopOptions,
            multiValue,
            salesmen,
            salesman_id,
            values_hidden_shops,
            changeMultiselectShop,
            schema,
            onSubmit,
            range,
            count_number
        };
    }
};
</script>

<template>
    <!-- modal start -->
	<div class="modal fade p-0" id="addCompany" tabindex="-1" aria-labelledby="editLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-md">
			<div class="d-flex flex-wrap w-100 position-relative event-auto">
				
        <div class="card shadow-none">
					<Form class="custom-form mt-2" @submit="doSubmit" @keydown="errors_v.clear($event.target.name)" :validation-schema="schema" v-slot="{ errors }">
          <div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
						<div class="content-card-left w-100">
							<h4 class="card-title text-white fs-4">Comapny</h4>
							<p class="fw-light text-white mb-0 pt-1">Create a New Company</p>
						</div>
					</div>
					<div class="card-body" >
						
							<div class="position-relative mb-5">
								<Field type="text" name="company_name" :class="[form.company_name=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.company_name"/>
								<label for="" class="mb-0 placeholder-label">Name</label>
                <p v-if="errors.company_name" class="invalid-message mb-0 pt-2">
                      {{errors.company_name}}
                </p>
                <p v-if="errors_v.get('name')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('name')}}
                  </p>
              </div>
							<div class="position-relative form-group">
								<Field name="company_type" class="form-select custom-select" @click="selectFocus = true" as="select" v-model="form.company_type"> 
									<option value="">select Your Type</option>
									<option value="sole">Sole</option>
									<option value="distribution">Distribution</option>
									<option value="both">Both</option>
								</Field>
								<label :class="[selectFocus ? 'focus':'', 'position-absolute select-label']">Type</label>
								<i class="bi bi-caret-down-fill select-arrow position-absolute"></i>
                <p v-if="errors.company_type" class="invalid-message mb-0 pt-2">
                      {{errors.company_type}}
                </p>
                <p v-if="errors_v.get('type')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('type')}}
                  </p>
              </div>
							<div class="position-relative mb-5">
								<Field type="number" name="whatsapp_number" :class="[form.whatsapp_number=='' ? '' : 'active', 'border-0 position-relative w-100 resize-none']" v-model="form.whatsapp_number"/>
								<label for="" class="mb-0 placeholder-label">Mobile</label>
                <p v-if="errors.whatsapp_number" class="invalid-message mb-0 pt-2">
                      {{errors.whatsapp_number}}
                </p>
                <p v-if="errors_v.get('mobile')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('mobile')}}
                  </p>
              </div>
							<div class="position-relative">
								<Field name="address" :class="[form.address=='' ? '' : 'active', 'border-0 position-relative w-100 resize-none']" v-model="form.address" rows="3" as="textarea"></Field>
								<label for="" class="mb-0 placeholder-label">Address</label>
                <p v-if="errors.address" class="invalid-message mb-0 pt-2">
                      {{errors.address}}
                </p>
                <p v-if="errors_v.get('address')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('address')}}
                  </p>
              </div>
						
					</div>
					<div class="card-footer px-4 pb-0 border-0 bg-transparent mb-3">
						<button class="btn btn-primary w-100" :disabled="searchDisabled">
                  <div v-if="loader" class="spinner-border me-2" role="status"></div>Add Company
                </button>
					</div>
          </Form>
				</div>
        
			</div>
		</div>			
	</div>
  <!-- modal end -->
  
</template>

<script>
    import {ref, reactive } from 'vue';
    import Errors from "@/utils/Errors";
    import { Form, Field  } from 'vee-validate';
    import * as Yup from 'yup'; 
    import CompanyServices from '@/services/CompanyServices';
    
export default{
    components:{
            Form,
            Field
        },
    
    setup(props,{emit}){
        const loader=ref(false);
        const searchDisabled=ref(false);
        
        const initialState = {
          company_name: "",
          company_type: "",
          whatsapp_number: "",
          address: ""
        };
        
        const form = reactive({ ...initialState });

        const schema = Yup.object().shape({
            company_name: Yup.string()
                .required('Company Name is required'),
            company_type: Yup.string()
                .required('Company Type is required'),
            whatsapp_number: Yup.number()
                .typeError("Only Numbers Allowed"),
            address: Yup.string()
                .required("Address required")
        });
      
        const errors_v = reactive(new Errors());
        function resetForm() {
            Object.assign(form, initialState);
          }
        const doSubmit = async(values, actions) => {
          
          loader.value=true;
          searchDisabled.value=true;
          const payload = {
            company_name: form.company_name,
            company_type: form.company_type,
            whatsapp_number: form.whatsapp_number,
            address: form.address
          };
          


          await CompanyServices.insertCompany(payload)
              .then(async response=>{
                
                const myModalEl = document.getElementById('addCompany');
                const modal = window.bootstrap.Modal.getInstance(myModalEl);
                modal.hide();
                resetForm();
                emit('alertEvent', 'add');
                loader.value=false;
                searchDisabled.value=false;
                //this.$refs.obs.reset();
                actions.resetForm()
                
                
              })
              .catch(error => {
                
                loader.value=false;
                searchDisabled.value=false;
                if(error.response.status==422){
                  errors_v.record(error.response.data.errors);
                }
              });
        }
        return {
            schema,
            form,
            doSubmit,
            errors_v,
            resetForm,
            loader,
            searchDisabled            

        }
    }
}
</script>
<template>
    <!-- Inner content -->
    <div class="inner-content">
        <section>
            <div class="container-fluid">
                <div class="row m-0">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative py-4">
                                <div class="content-card-left">
                                    <h4 class="card-title text-white">Inventory Out</h4>
                                </div>
                                <div class="content-card-right ms-auto">
                                    <router-link to="/payment/add"  class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle">
                                        <i class="bi bi-plus-square-fill text-white"></i>
                                    </router-link>
                                </div>
                            </div>
                            <div class="card-body px-0" >
                                <div class="header-content">
                                    <Form @submit="onSubmit" :validation-schema="schema" class="custom-form mt-2 shops-product" ref="myForm">
                                        <div class="row mx-0 px-4">
                                            <div class="col-lg-11 col-md-11 col-sm-10 col-9">
                                                <div class="position-relative form-group">
                                                    <Field as="select" name="salesman_id" class="form-select custom-select" @click="selectFocus = true" v-model="salesman_id">
                                                        <option value="0">Choose Salesman</option>
                                                        <option v-for="salesman in salesmen" :value="salesman.id" :key="salesman.id">{{salesman.first_name+" "+salesman.last_name}}</option>
                                                        
                                                    </Field>
                                                    <label :class="[selectFocus ? 'focus':'', 'position-absolute select-label']">Salesman</label>
                                                    <i class="bi bi-caret-down-fill select-arrow position-absolute"></i>
                                                    <ErrorMessage class="invalid-message mb-0 pt-2" name="salesman_id" />
                                                    <p v-if="errors_v.get('salesmanId') && (salesman_id==null || salesman_id=='')" class="invalid-message mb-0 pt-2">
                                                        {{errors_v.get('salesmanId')}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-1 col-md-1 col-sm-2  col-3 align-self-center">
                                                <a href="#" class="d-flex flex-wrap align-items-center justify-content-center hover-btn rounded-circle ms-auto" @click.prevent>
                                                    <i class="bi bi-arrow-repeat"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="row mx-0 px-4 mt-4">
                                            <div class="col-lg-11 col-md-11 col-sm-10 col-9">
                                                <div class="position-relative form-group">
                                                    <Field as="select" name="sale_type" class="form-select custom-select" @click="selectFocus = true" v-model="sale_type" @change="resetCustomForm">
                                                        <option value="0">Choose Sale Type</option>
                                                        <option value="retail">Retail</option>
                                                        <option value="wholesale">Wholesale</option>
                                                        <option value="initial">Initial</option>
                                                        
                                                    </Field>
                                                    <label :class="[selectFocus ? 'focus':'', 'position-absolute select-label']">Sale Type</label>
                                                    <i class="bi bi-caret-down-fill select-arrow position-absolute"></i>
                                                    <ErrorMessage class="invalid-message mb-0 pt-2" name="sale_type" />
                                                    <p v-if="errors_v.get('saleType') && (sale_type==null || sale_type=='')" class="invalid-message mb-0 pt-2">
                                                        {{errors_v.get('saleType')}}
                                                    </p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <!-- Shop rows -->
                                        <fieldset class="InputGroup row mx-0 shop-row mt-4 pt-3 pb-4" v-for="(shop, idx) in shops" :key="shop.id">
                                              
                                                <div class="col-12 px-0">
                                                    <div class="row mx-0 px-4 gy-4">
                                                        <div class="col-12" v-if="idx===0">
                                                            <div class="d-flex flex-wrap align-items-center mb-2">
                                                                <h6 class="mb-0 text-primary">Shop#{{idx+1}}</h6>
                                                                <div class="text-center add-rows-wrapper ms-auto">
                                                                    <span class="d-inline-block add bi bi-plus-circle-fill text-primary" @click="add_shop"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12" v-else>
                                                            <div class="d-flex flex-wrap align-items-center mb-2">
                                                                <h6 class="mb-0 text-primary">Shop#{{idx+1}}</h6>
                                                                <div class="text-center add-rows-wrapper ms-auto">
                                                                    <span class="d-inline-block add bi bi-dash-circle-fill text-primary" @click="remove_shop(shop)"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-3 col-md-6">
                                                            <Multiselect @select="changeMultiselectShop(shop.shop_model,idx)"
                                                                v-model="shop.shop_model"
                                                                :options="shopOptions"
                                                                class="custom-multiselect"
                                                                placeholder="Shop"
                                                                :searchable="true"
                                                                :showOptions="true"
                                                                mode="single"
                                                                label="shopName"
                                                                trackBy	= "shop_name"
                                                                valueProp="id"
                                                                required: true
                                                                :id="`multiselect_${idx}`"
                                                                :name="`shops[${idx}].multiselect`"
                                                            />
                                                            <Field type="hidden" :id="`shopid_${idx}`" :name="`shops[${idx}].shopid`" v-model="values_hidden_shops[idx]" />
                                                            <p v-if="errors_v.get('billingData.'+idx+'.shopid') && (values_hidden_shops[idx]==null || values_hidden_shops[idx]=='')" class="invalid-message mb-0 pt-2">
                                                                {{errors_v.get('billingData.'+idx+'.shopid')}}
                                                            </p>
                                                            <ErrorMessage class="invalid-message mb-0 pt-2" :name="`shops[${idx}].shopid`" />
                                                        </div>
                                                        <div class="col-xl-3 col-md-6">
                                                            <div class="position-relative">
                                                                <Field type="number" :id="`discount_${idx}`" :name="`shops[${idx}].discount`" :class="[shop.discount=='' ? '' : 'active', 'border-input position-relative w-100']" v-model="shop.discount"/>
                                                                <label for="" class="mb-0 placeholder-label w-auto">Discount</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-3 col-md-6">
                                                            <div class="position-relative">
                                                                <Field type="number" :id="`amount_${idx}`" :name="`shops[${idx}].amount`" :class="[shop.amount=='' ? '' : 'active', 'border-input position-relative w-100']" v-model="shop.amount"/>
                                                                <label for="" class="mb-0 placeholder-label w-auto">Amount</label>
                                                                <p class="invalid-message mb-0 pt-2">
                                                                    <span>prev balance: {{shop.prev_balance}} </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-3 col-md-6">
                                                            <div class="position-relative">
                                                                <Field type="text" :id="`payment_type_${idx}`" :name="`shops[${idx}].payment_type`" :class="[shop.payment_type=='' ? '' : 'active', 'border-input position-relative w-100']" v-model="shop.payment_type"/>
                                                                <label for="" class="mb-0 placeholder-label w-auto">Payment</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <fieldset class="InputGroup row mx-0" v-for="(product, pdx) in shop.products" :key="product.id">
                                                        <!-- Products row -->
                                                        <div class="row mx-0 px-4 product-row mt-3 gy-4">
                                                            <div class="col-12" v-if="pdx===0">
                                                                <div class="d-flex flex-wrap align-items-center mb-2">
                                                                    <h6 class="mb-0 text-primary">Product#{{pdx+1}}</h6>
                                                                    <div class="text-center add-rows-wrapper ms-auto">
                                                                        <span class="d-inline-block add bi bi-plus-circle-fill text-primary" @click="add_product(idx)" ></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12" v-else>
                                                                <div class="d-flex flex-wrap align-items-center mb-2">
                                                                    <h6 class="mb-0 text-primary">Product#{{pdx+1}}</h6>
                                                                    <div class="text-center add-rows-wrapper ms-auto">
                                                                        <span class="d-inline-block add bi bi-dash-circle-fill text-primary" @click="remove_product(idx,product)"></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-3 col-md-6">
                                                                <Multiselect @select="changeMultiselectProduct(shop.products[pdx].pmodel,idx, pdx)"
                                                                    v-model="shop.products[pdx].pmodel"
                                                                    :options="productOptions"
                                                                    class="custom-multiselect"
                                                                    placeholder="Products"
                                                                    :searchable="true"
                                                                    :showOptions="true"
                                                                    mode="single"
                                                                    label="name"
                                                                    trackBy	= "name"
                                                                    valueProp="id"
                                                                    required: true
                                                                    :id="`multiselect_${idx}`"
                                                                    :name="`shops[${idx}].products_multiselect`"
                                                                />
                                                                <Field type="hidden" :id="`hidden_val_${pdx}`" :name="`shops[${idx}].products[${pdx}].hidden_val`" v-model="shops[idx].products[pdx].hidden_val" :key="pdx" />
                                                                <p v-if="errors_v.get('billingData.'+idx+'.products.'+pdx+'.hidden_val') && (shops[idx].products[pdx].hidden_val==null || shops[idx].products[pdx].hidden_val=='')" class="invalid-message mb-0 pt-2">
                                                                    {{errors_v.get('billingData.'+idx+'.products.'+pdx+'.hidden_val')}}
                                                                </p>
                                                                <ErrorMessage class="invalid-message mb-0 pt-2" :name="`shops[${idx}].products[${pdx}].hidden_val`" />
                                                            </div>
                                                            <div class="col-xl-3 col-md-6">
                                                                <div class="position-relative form-group">
                                                                    <Field as="select" :id="`ptype_${pdx}`" :name="`shops[${idx}].products[${pdx}].ptype`" class="form-select custom-select" @click="selectFocus = true" v-model="product.ptype" :key="pdx" @change="updateTotalAmount(idx,pdx)">
                                                                        <option value="boxes">Boxes</option>
                                                                        <option value="cartons">Cartons</option>
                                                                    </Field>
                                                                    <label :class="[selectFocus ? 'focus':'', 'position-absolute select-label']">Quantity In</label>
                                                                    <i class="bi bi-caret-down-fill select-arrow position-absolute"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-3 col-md-6">
                                                                <div class="position-relative">
                                                                    <Field type="number" :id="`quantity_${pdx}`" :name="`shops[${idx}].products[${pdx}].quantity`" :key="pdx"  :class="[shops[idx].products[pdx].quantity=='' ? '' : 'active', 'border-input position-relative w-100']" v-model="shops[idx].products[pdx].quantity" @change="updateTotalAmount(idx,pdx)"/>
                                                                    <label for="" class="mb-0 placeholder-label w-auto">Quantity</label>
                                                                    <p v-if="errors_v.get('billingData.'+idx+'.products.'+pdx+'.quantity')" class="invalid-message mb-0 pt-2">
                                                                    {{errors_v.get('billingData.'+idx+'.products.'+pdx+'.quantity')}}
                                                                </p>
                                                                <ErrorMessage class="invalid-message mb-0 pt-2" :name="`shops[${idx}].products[${pdx}].quantity`" />
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-3 col-md-6">
                                                                <div class="position-relative">
                                                                    <input type="text"  :name="`shops[${idx}].products[${pdx}].total_amount`"   class='active border-input position-relative w-100' v-model="shops[idx].products[pdx].total_amount" disabled/>
                                                                    <label for="" class="mb-0 placeholder-label w-auto">Amount: </label>
                                                                   
                                                                
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <div class="row mx-0 px-4 mt-5">
                                                        <div class="col-auto ms-auto">
                                                                <div class="position-relative">
                                                                    <input type="text"     class='active border-input position-relative w-100' v-model="shops[idx].total_amount" disabled/>
                                                                    <label for="" class="mb-0 placeholder-label w-auto">Total Amount: </label>
                                                                </div>
                                                    </div>
                                                    </div>
                                                </div>
                                          
                                        </fieldset>
                                        <div class="card-footer px-4 pb-0 border-0 bg-transparent mb-3">
                                            <button class="btn btn-primary w-100" :disabled="searchDisabled">
                                                <div v-if="loader" class="spinner-border me-2" role="status"></div>Add Billing
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
	<add-credit @alert-event="showGrowl"></add-credit>
	<Alert
      v-if="growlMessage"
      :name="alert_m.name"
      :alertHeading="alert_m.heading"
      :alertDescription="alert_m.description">
  </Alert>
</template>
<script>
	import { ref, reactive, onMounted, watch } from 'vue';
    import "@/assets/scss/pages/_add-billing.scss";
    // Add this for multiselect
	import Multiselect from '@vueform/multiselect';
	import "@vueform/multiselect/themes/default.css";
    import salesmanServices from '@/services/SalesmanServices';
    import salesmanShopServices from '@/services/SalesmanShopServices';
    import billingServices from '@/services/BillingServices';
    import productServices from '@/services/ProductServices';
    import {Form, Field, ErrorMessage} from 'vee-validate';
    import Errors from "@/utils/Errors";
    import * as yup from "yup";
    import {useRouter} from "vue-router";
    import BalanceSheetServices from '@/services/BalanceSheetServices';
    import AddCredit from '@/components/modals/AddCreditModal.vue';
    import Alert from "@/components/alert";
	export default {
	    components: {
             Multiselect,
             Form,
             Field,
             ErrorMessage,
             AddCredit,
             Alert
	    },
		setup() {
            const salesmen=ref([]);
            const salesman_id=ref(0);
            const sale_type=ref("");
            const shopOptions=ref("");
            const productOptions=ref("");
            const loader=ref(false);
            const router = useRouter();
            const searchDisabled=ref(false);
        
            const alert_m = reactive({
                name: '',
                heading: '',
                description: ''
            });
	        const growlMessage = ref(false);
            const showGrowl = (alertType) =>{
                if(alertType=="add"){
                    alert_m.name="success";
                    alert_m.description="Record Added Successfully";
                }
                else if(alertType=="edit"){
                    alert_m.name="success";
                    alert_m.description="Record Updated Successfully";
                }
                else{
                    alert_m.name="danger";
                    alert_m.description="No Record Found. Please try with valid data";
                }
                
                
                alert_m.heading="Credit";
                growlMessage.value=true;
                setTimeout(() => { growlMessage.value=false; }, 5000)
            }
            const shops=ref([{
                id: Date.now(),
                shop_model: "",
                discount: "",
                amount: "",
                payment_type: "",
                prev_balance: 0,
                total_amount:"",
                products:[{
                    id: Date.now(),
                    pmodel: "",
                    ptype: 'boxes',
                    quantity: "",
                    hidden_val: "",
                    total_amount: ""
                }]
            }]);
            const errors_v = reactive(new Errors());
            const values_hidden_shops=ref({});
            const schema = yup.object().shape({
            salesman_id: yup.number().moreThan(0, 'Salesman Required'),
            sale_type: yup.string().required().label("Sale Type"),
            shops: yup
                .array()
                .of(
                yup.object().shape({
                    shopid: yup.number().required().label("Shop Name"),
                    products: yup.array()
                                    .of(
                                        yup.object().shape({
                                            hidden_val: yup.number().moreThan(0, 'Product Name Required').typeError("Product Name Required").label("Quantity"),
                                            quantity: yup.number().moreThan(0, 'Product Quantity Required').typeError("Only Numbers Allowed").label("Quantity"),
                                        })
                                    )
                })
                )
					
		});
            const add_shop= ()=>{
                shops.value.push({
                    id: Date.now(),
                    shop_model: "",
                    discount: "",
                    amount: "",
                    payment_type: "",
                    prev_balance: 0,
                    total_amount:"",
                    products:[{
                        id: Date.now(),
                        pmodel: "",
                        ptype: 'boxes',
                        quantity: "",
                        total_amount: "",
                        hidden_val: ""
                    }]
                });
		    }

            const remove_shop=(item)=>{
                const index = shops.value.indexOf(item);
                if (index === -1){
                    return;
                }
                shops.value.splice(index, 1);
            }

            const add_product= (idx)=>{
                shops.value[idx].products.push({
                        id: Date.now(),
                        pmodel: "",
                        ptype: 'boxes',
                        quantity: "",
                        total_amount: "",
                        
                    });
                    
		    }

            const remove_product=(idx,item)=>{
                const index = shops.value[idx].products.indexOf(item);
                if (index === -1){
                    return;
                }
                shops.value[idx].products.splice(index, 1);

                shops.value[idx].total_amount  = shops.value[idx].products.reduce((sum, products) => parseInt(sum + products.total_amount), 0)||0;

            }
            
            const updateTotalAmount=(idx,pdx)=>{
                
                if(idx>=0 && pdx>=0){

                    const product_id=shops.value[idx].products[pdx].hidden_val;
                    
                    const obj = productOptions.value.find(o => o.id === product_id);
                    let quantity=shops.value[idx].products[pdx].quantity;
                    const ptype=shops.value[idx].products[pdx].ptype;
                    if(ptype==="cartons"){
                        quantity = eval(quantity)*eval(obj.boxes_per_carton);            
                    }
                    if(product_id>0 && quantity>0 && sale_type.value!=""){
                        if(sale_type.value==="retail"){
                            
                            shops.value[idx].products[pdx].total_amount=obj.retail_price*quantity;
                        }
                        else if(sale_type.value==="wholesale"){
                            shops.value[idx].products[pdx].total_amount=obj.whole_sale_price*quantity;
                        }
                        else{
                            shops.value[idx].products[pdx].total_amount=obj.initial_price_per_unit*quantity;
                        }
                        
                        } else {
                        shops.value[idx].products[pdx].total_amount = "";
                        
                        }
                        shops.value[idx].total_amount  = shops.value[idx].products.reduce((sum, products) => parseInt(sum + products.total_amount), 0)||0;
                        }
                }

            

            async function changeMultiselectShop(value,idx){
                
                await BalanceSheetServices.getPrevBalance(value,salesman_id.value)
                .then(response=>{
                    shops.value[idx].prev_balance=response.data.remaining_amount;
                })
                values_hidden_shops.value[idx]=value;
            }

            function changeMultiselectProduct(value,idx,pdx){

                shops.value[idx].products[pdx].hidden_val=value;
                updateTotalAmount(idx,pdx);
            }

            
            function resetCustomForm() {
                shops.value.length=0;
                shops.value=[{
                    id: Date.now(),
                    shop_model: "",
                    discount: "",
                    amount: "",
                    payment_type: "",
                    prev_balance: 0,
                    products:[{
                        id: Date.now(),
                        pmodel: "",
                        ptype: 'boxes',
                        quantity: "",
                        
                        hidden_val: ""
                    }]
                }]
                values_hidden_shops.value={};
            }
            
            watch(()=>salesman_id.value, (currentValue, oldValue) => {
                if(currentValue!=oldValue && currentValue!=""){
                    salesmanShopServices.getSalesmanShops(salesman_id.value)
                    .then(response=>{
                        console.log(response.data.data);
                        shopOptions.value= response.data.data;
                        
                    })
                    resetCustomForm();
                   
                }
            });
           
            onMounted(async () => {
                await salesmanServices.paginateSalesman()
                .then(response=>{
                    salesmen.value= response.data.data;
                })

                await productServices.getAllProducts()
                .then(response=>{
                    productOptions.value= response.data;
                })
            });

            const onSubmit = async(values, actions) => {
                loader.value=true;
                searchDisabled.value=true;

            let payload={
                "billingData": values.shops,
                "salesmanId": values.salesman_id,
                "saleType": values.sale_type
			}
            if(salesman_id.value===0){
                 payload={
                    "billingData": values.shops,
                    "salesmanId": "",
                    "saleType": "",
				}
            }
            if(sale_type.value===""){
                 payload={
                    "billingData": values.shops,
                    "salesmanId": "",
                    "saleType": values.salesman_id,
				}
            }
            await billingServices.addBilling(payload)
            .then(async response=>{
                resetCustomForm();
                loader.value=false;
                searchDisabled.value=false;
                router.push('/billing');
            })
            .catch(error => {
                loader.value=false;
                searchDisabled.value=false;
                if(error.response.status==422){
                    errors_v.record(error.response.data.errors);
                }
            });
			}

			const selectFocus = ref(false);
			
			
			
			const balance = ref("");
			const amountModal = ref("");
			const paymentModal = ref("");
			
			return {
				selectFocus,
				
                
                
				balance,
				amountModal,
				paymentModal,
                
                
                
                shops,
                add_shop,
                remove_shop,
                changeMultiselectShop,
                salesmen,
                salesman_id,
                sale_type,
                shopOptions,
                productOptions,
                onSubmit,
                values_hidden_shops,
                add_product,
                remove_product,
                changeMultiselectProduct,
                errors_v,
                schema,
                resetCustomForm,
                loader,
                searchDisabled,
                router,
                showGrowl,
		        alert_m,
		        growlMessage,
                updateTotalAmount,
                
			}
		}
	}
</script>
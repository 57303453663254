<script>
import { watch, ref } from 'vue';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import shopServices from '@/services/ShopServices';
import moment from 'moment';
export default {
  props: {
    shop_id: Number,
    Pdfcounter: Number,
  },
  setup(props, { emit }) {
    const pdfData = ref([]);
    const documentDefinition = ref({});
    const body = ref([]);
    const loadPdf = async () => {
      await shopServices
        .getShopSalesman(props.shop_id)

        .then((response) => {
          body.value = [];
          pdfData.value = response.data.data;
        })
        .catch(() => {
          pdfData.value = [];
          body.value = [];
          emit('alertEvent', 'no_data');
        })
        .then(() => {
          const columns = [
            { text: 'id', style: 'defaultFont', alignment: 'center' },
            {
              text: 'Salesman Name ',
              style: 'defaultFont',
              alignment: 'center',
            },
            { text: 'Balance', style: 'defaultFont', alignment: 'center' },
          ];
          body.value.push(columns);
          let i = 1;
          pdfData.value.forEach(function (row) {
            const dataRow = [];
            const id = {
              text: i.toString(),
              style: 'defaultFont',
              alignment: 'center',
            };

            dataRow.push(id);
            const name = {
              style: 'defaultFont',
              alignment: 'center',
            };
            row['first_name'] != ''
              ? (name['text'] = row['first_name'] + ' ' + row['last_name'])
              : (name['text'] = '');

            dataRow.push(name);
            const balance = {
              text: row['remaining_amount'],
              style: 'defaultFont',
              alignment: 'center',
            };

            dataRow.push(balance);

            i++;
            body.value.push(dataRow);
          });
        })
        .then(() => {
          documentDefinition.value = {
            content: [
              {
                columns: [
                  {
                    width: '50%',
                    text: 'All Salesman',
                    style: ['defaultFont', 'mb2'],
                  },
                ],
              },
              {
                columns: [
                  {
                    width: '38%',
                    text: 'Print Date : ' + moment().format('YYYY-MM-DD'),
                    style: ['defaultFont', 'textLeft', 'mb15'],
                  },
                ],
              },
              {
                table: {
                  headerRows: 1,
                  widths: [30, '*','*'],
                  body: body.value,
                },
              },
              {},
            ],
            styles: {
              defaultFont: {
                fontSize: 11,
                bold: true,
              },
              textRight: {
                alignment: 'right',
              },
              textCenter: {
                alignment: 'center',
              },
              mb2: {
                margin: [0, 0, 0, 2],
              },
              mt2: {
                margin: [0, 4, 0, 0],
              },
            },
          };
        })
        .then(() => {
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          pdfMake.createPdf(documentDefinition.value).open();
          body.value = [];
        });
    };
    watch(
      () => props.Pdfcounter,
      (currentValue, oldValue) => {
        if (currentValue != oldValue) {
          loadPdf();
        }
      }
    );
    return {
      pdfData,
      documentDefinition,
      body,
      loadPdf,
    };
  },
};
</script>

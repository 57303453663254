<template>
    <!-- Inner content -->
    <div class="inner-content">
        <section>
            <div class="container-fluid">
                <div class="row m-0">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
                                <div class="content-card-left">
                                    <h4 class="card-title text-white">Inventory</h4>
                                </div>
                                <div class="content-card-right ms-auto" v-if="user_role==='super_admin'">
                                    <a href="#addShop" data-bs-toggle="modal" data-bs-target="#addInventory" @click.prevent class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle">
                                        <i class="bi bi-plus-square-fill text-white"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="card-body" >
                                <div class="header-content">
                                    <form class="table-header mx-0 mb-1 custom-form position-sticky">
                                        <div class="row m-0 align-items-center">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 px-0">
                                                <h4 class="filter mb-lg-0 mb-md-0 mb-3">
                                                        <i class="bi bi-funnel me-1 position-relative"></i>Filters
                                                </h4>
                                            </div>
                                            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 px-0 ms-lg-auto ms-md-auto">
                                                <div class="position-relative w-100">
                                                    
                                                    <Multiselect 
                                                        v-model="searchData.companyId"
                                                        :options="companies"
                                                        class="custom-multiselect table-header-multi h-auto"
                                                        placeholder="Search Company"
                                                        :searchable="true"
                                                        :showOptions="true"
                                                        mode="single"
                                                        label="name"
                                                        trackBy	= "name"
                                                        valueProp="id"
                                                        required: true
                                                    />
                                                    
                                                    <i class="bi bi-search position-absolute search-multi"></i>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 px-lg-0 px-md-0 px-sm-3 px-0 ms-lg-3 ms-md-3 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                                <div class="position-relative">
                                                    <input type="text" class="position-relative w-100 search-field" placeholder="Search by Product" v-model="searchData.productName">
                                                    <i class="bi bi-search position-absolute"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="table-responsive mb-0">
                                        <table id="my-table"  class="custom-table shop-table">
                                            <thead class="small-font">
                                                <tr>
                                                    <th class="text-capitalize">Id</th>
                                                    <th class="text-capitalize">Date</th>
                                                    <th class="text-capitalize">
                                                        <div class="d-flex flex-wrap align-items-center">
                                                            Company
                                                            
                                                        </div>		
                                                    </th>
                                                    <th class="text-capitalize">Product</th>
                                                    <th class="text-capitalize">Quantity</th>
                                                    <th class="text-capitalize">Type</th>
                                                    <th class="text-capitalize">Total</th>
                                                    <!--<th class="text-capitalize text-center">actions</th>-->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="inventory in inventories" :key="inventory.id">
                                                    <td>{{inventory.id}}</td>
                                                    <td>{{formatDate(inventory.created_at)}}</td>
                                                    <td>{{inventory.company_name}}</td>
                                                    <td>{{inventory.product_name}}</td>
                                                    <td>{{inventory.quantity}}</td>
                                                    <td>{{inventory.type}}</td>
                                                    <td>{{inventory.total}}</td>
                                                    <!--<td class="action text-center">
                                                        <a href="#" class="edit d-inline-block text-center" data-bs-toggle="modal" data-bs-target="#viewsalesman" @click.prevent>
                                                            <img src="@/assets/images/pdf.png" alt="pdf-image" class="small-image">
                                                        </a>
                                                    </td>
                                                    -->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent">
                                <p class="d-lg-inline-block table-data">
                                Showing
                                {{ current_page * entries_per_page - (entries_per_page - 1) }}
                                to
                                {{
                                    current_page * entries_per_page -
                                    (entries_per_page - inventories.length)
                                }}
                                of {{ total_count }} entries.
                                </p>
                
                                <!--<pagination :v-model="page" :records="44" :per-page="15" @paginate="myCallback"/>-->
                                <div class="d-inline-block ms-auto me-lg-0 me-md-0 me-sm-0 me-auto mb-lg-0 mb-md-0 mb-sm-0 mb-4 pagination-wrapper">
                                    <v-pagination class="d-flex flex-lg-wrap flex-md-wrap flex-sm-wrap align-items-center p-0 m-0"
                                        v-model="current_page"
                                        :pages="total_pages"
                                        :range-size="3"
                                        @update:modelValue="loadData"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <inventory-add-modal @alert-event="showGrowl" ></inventory-add-modal>
	<Alert
      v-if="growlMessage"
      :name="alert_m.name"
      :alertHeading="alert_m.heading"
      :alertDescription="alert_m.description">
  </Alert>
</template>
<script>
	import { ref, reactive, onMounted, watch,computed } from 'vue';
	import inventoryServices from '@/services/InventoryServices';
    import CompanyServices from '@/services/CompanyServices';
    import moment from 'moment'
    import VPagination from "@hennge/vue3-pagination";
    import "@/assets/scss/pages/_daily.scss";
	// Add this for multiselect
	import Multiselect from '@vueform/multiselect';
	import "@vueform/multiselect/themes/default.css";
    import Alert from "@/components/alert.vue";
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import InventoryAddModal from '@/components/modals/InventoryAddModal.vue';
	export default {
	    components: {
			 Multiselect,
             VPagination,
             InventoryAddModal,
             Alert,
	    },
		setup() {
            const total_count=ref(0);
            const entries_per_page=ref(0);
            const inventories=ref([]);
            const total_pages= ref(0);
            const store=useStore();
            const user_role=ref('');
            const router= useRouter();
            const current_page= ref(1);
            const alert_m = reactive({
                name: '',
                heading: '',
                description: ''
            });
            const companies=ref([]);
            const searchData = reactive({
                companyId: "",
                productName: ""
            });
            const growlMessage = ref(false);
            const showGrowl = (alertType) =>{
                if(alertType=="add"){
                    alert_m.name="success";
                    alert_m.description="Record Added Successfully";
                }
                else if(alertType=="edit"){
                    alert_m.name="success";
                    alert_m.description="Record Updated Successfully";
                }
                else{
                    alert_m.name="danger";
                    alert_m.description="No Record Found. Please try with valid data";
                }
            
            
                alert_m.heading="Inventory";
                growlMessage.value=true;
                setTimeout(() => { growlMessage.value=false; }, 5000)
            }
            const loadData= async()=>{
                await inventoryServices.paginateDailyInventories(current_page.value, searchData)
                .then(response=>{
                    inventories.value= response.data.data;
                    total_pages.value=response.data.last_page;
                    current_page.value=response.data.current_page;
                    entries_per_page.value=response.data.per_page;
                    total_count.value=response.data.total;
                })
                .catch(error => {  
                    alert("error");
                });
            }
            const loadCompanies=async ()=>{
                await CompanyServices.paginateCompanies()
                .then(response=>{
                    companies.value= response.data.data;
                })
            }
            onMounted( () => {
                loadData();
                loadCompanies();
            });
            watch([searchData], ([newSearchData], [prevSearchData]) => {
                if(newSearchData.companyId==null){
                    newSearchData.companyId="";
                }
                loadData();
            });
            const formatDate = (value)=>{
                if (value) {
                return moment(String(value)).format('MMM Do YY');
                }
            }
			const selectFocus = ref(false);
			watch(async () => {
            try {
                const user = computed(() => store.state.Auth.user);
                user_role.value=user.value.role.name;
              } catch (e) {
                await router.push('/');
              }
            });
			
			
			
			return {
                user_role,
				selectFocus,
                inventories,
                total_pages,
                current_page,
                loadData,
                formatDate,
                alert_m,
                growlMessage,
                showGrowl,
                searchData,
                companies,
                entries_per_page,
                total_count,
	        }
		}
	}
</script>
import * as API from "@/services/API";

export default {
   
    async insertBalance(payload){
        return await API.apiClient.post('/api/transactions',payload);
    },
    async getTransactions(company_id,page_number,startDate, endDate){
            return API.apiClient.get('/api/transaction/search/'+ company_id + '?page=' + page_number, {
                headers: {
                    'start-date': startDate,
                    'end-date': endDate
                }
            });
    }
}

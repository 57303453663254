<template>
    <!-- Edit Modal -->
    <div
        class="modal fade p-0"
        id="addGenerateDailtPDF"
        tabindex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-md">
            <div class="d-flex flex-wrap w-100 position-relative event-auto">
                <div class="card shadow-none">
                    <div
                        class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
                    >
                        <div class="content-card-left w-100">
                            <h4 class="card-title text-white fs-4">BalanceSheet</h4>
                            <p class="fw-light text-white mb-0 pt-1 ">
                                Generate PDF based on options
                            </p>
                        </div>
                    </div>
                    <div class="card-body">
                        <Form
                            @submit="onSubmit"
                            :validation-schema="schema"
                            class="custom-form mt-2"
                            ref="myForm"
                        >
                            <div class="position-relative form-group">
                                <Field
                                    as="select"
                                    name="salesman_id"
                                    class="form-select custom-select"
                                    @click="selectFocus = true"
                                    v-model="salesman_id"
                                >
                                    <option value="0">Choose Salesman</option>
                                    <option
                                        v-for="salesman in salesmen"
                                        :value="salesman.id"
                                        :key="salesman.id"
                                        >{{
                                            salesman.first_name + ' ' + salesman.last_name
                                        }}</option
                                    >
                                </Field>
                                <label
                                    :class="[
                                        selectFocus ? 'focus' : '',
                                        'position-absolute select-label'
                                    ]"
                                    >Salesman</label
                                >
                                <i class="bi bi-caret-down-fill select-arrow position-absolute"></i>
                                <ErrorMessage
                                    class="invalid-message mb-0 pt-2"
                                    name="salesman_id"
                                />
                            </div>
                            <div class=" form-group">
                                <v-date-picker
                                    :popover="{ visibility: 'click' }"
                                    class="custom-calendar left-caret-none"
                                    popover-align="right"
                                    v-model="range"
                                    is-range
                                    :columns="$screens({ default: 2, lg: 2 })"
                                >
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <div class="d-flex align-items-center ">
                                            <div
                                                class="position-relative calendar-input-wrapper clearfix w-100"
                                            >
                                                <i
                                                    class="bi bi-calendar3 position-absolute icon-left-placement icon-without-label"
                                                ></i>
                                                <input
                                                    placeholder="From"
                                                    :value="inputValue.start"
                                                    v-on="inputEvents.start"
                                                    class="border-0 icon-left position-relative float-left w-100"
                                                />
                                            </div>
                                            <span class="d-inline-block mx-3 mx-sm-4">To</span>
                                            <div
                                                class="position-relative calendar-input-wrapper clearfix w-100"
                                            >
                                                <i
                                                    class="bi bi-calendar3 position-absolute icon-left-placement icon-without-label"
                                                ></i>
                                                <input
                                                    placeholder="To"
                                                    :value="inputValue.end"
                                                    v-on="inputEvents.end"
                                                    class="border-0 icon-left position-relative float-left w-100"
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </v-date-picker>
                            </div>
                            <div
                                class="card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 mb-3 px-4 pb-0 border-0 bg-transparent"
                            >
                                <button class="btn btn-primary w-100">
                                    Generate
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <DailyCheckPdfRange :counter_all="count_number" :start_date="startDate"
    :end_date="endDate" :salesman_id="salesman_id"></DailyCheckPdfRange>
</template>

<script>
import { ref, onMounted, watch} from 'vue';
import '@vueform/multiselect/themes/default.css';
import salesmanServices from '@/services/SalesmanServices';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import moment from 'moment';
import DailyCheckPdfRange from '@/components/pdf/DailyCheckPdfRange.vue';
export default {
    components: {
        Form,
        Field,
        ErrorMessage,
        DailyCheckPdfRange,
    },
    setup() {
        const selectFocus = ref(false);
        const salesmen = ref([]);
        const salesman_id = ref(0);
        const count_number = ref(0);
        const range = ref({
			start: '',
            end: '',
        });        
        const startDate = ref('');
        const endDate = ref('');
        const multiValue = ref('');
        const closeModal = () => {
            const myModalEl = document.getElementById('addGenerate');
            const modal = window.bootstrap.Modal.getInstance(myModalEl);
            modal.hide();
        };
     
        const schema = yup.object().shape({
            salesman_id: yup.number().moreThan(0, 'Salesman Required')
        });
        onMounted(async () => {
            await salesmanServices.paginateSalesman().then(response => {
                salesmen.value = response.data.data;
            });
        });
        const onSubmit = async (values, actions) => {
            count_number.value += 1;
        };
        watch(
          async () => range.value,
          (currentValue, oldValue) => {
            if (currentValue != oldValue && currentValue != '') {
              startDate.value = moment(String(range.value.start)).format(
                'YYYY-MM-DD'
              );
              endDate.value = moment(String(range.value.end)).format('YYYY-MM-DD');
            }
          }
        );
        return {
            selectFocus,
            closeModal,
            multiValue,
            salesmen,
            salesman_id,
            schema,
            onSubmit,
            range,
            count_number,
            startDate,
            endDate
        };
    }
};
</script>

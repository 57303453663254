<script>
import { watch, ref, reactive } from 'vue';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import balanceSheetServices from '@/services/BalanceSheetServices';
import moment from 'moment';
export default {
  props: {
    counter_all: Number,
    date: String,
  },
  setup(props) {
    const dailyData = ref([]);
    const documentDefinition = ref({});
    const body = ref([]);
    const totals=reactive({
      credit:0,
      debit:0,
      return:0,
    })
    const loadPdf = async () => {
      await balanceSheetServices
        .getDailyCheck(moment(String(props.date)).format('YYYY-MM-DD'))

        .then((response) => {
          dailyData.value = response.data.data;
        })
        .then(() => {
          const columns = [
            { text: 'id', style: 'defaultFont', alignment: 'center' },
            { text: 'Name ', style: 'defaultFont', alignment: 'center' },
            { text: 'Debit', style: 'defaultFont', alignment: 'center' },
            { text: 'Credit', style: 'defaultFont', alignment: 'center' },
            { text: 'Return', style: 'defaultFont', alignment: 'center' },
          ];
          body.value.push(columns);
          let i = 1;
          dailyData.value.forEach(function (row) {
            const dataRow = [];
            const id = {
              text: i.toString(),
              style: 'defaultFont',
              alignment: 'center',
            };

            dataRow.push(id);

            const name = {
              style: 'defaultFont',
              alignment: 'center',
            };

            if (row['first_name'] != '') {
              name['text'] = row['first_name'] + ' ' + row['last_name'];
            } else {
              name['text'] = '';
            }
            dataRow.push(name);

            const debit = {
              text: row['debit'].toString(),
              style: 'defaultFont',
              alignment: 'center',
            };
            dataRow.push(debit);

            const credit = {
              text: row['credit'].toString(),
              style: 'defaultFont',
              alignment: 'center',
            };

            dataRow.push(credit);

            const back = {
              text: row['return'].toString(),
              style: 'defaultFont',
              alignment: 'center',
            };
            dataRow.push(back);
            totals.credit += eval(row['credit']);
            totals.debit += eval(row['debit']);
            totals.return += eval(row['return']);
            i++;
            body.value.push(dataRow);
          });
        })
        .then(() => {
          documentDefinition.value = {
            content: [
              {
                columns: [
                  {
                    width: '50%',
                    text: 'All Salesman Daily Report:',
                    style: ['defaultFont', 'mb2'],
                  },
                ],
              },

        {
            columns: [
                {
                width: '38%',
                text: 'Date:'+moment(String(props.date)).format('DD-MM-YYYY'),
                style: [ 'defaultFont', 'textLeft', 'mb15']
                },
            ],
        },

              {
                table: {
                  headerRows: 1,
                  widths: [30, '*', '*', '*', '*'],
                  body: body.value,
                },
              },
              {
                columns: [
                  {
                    width: '7%',
                    text: 'Total: :',
                    style: ['defaultFont', 'textRight', 'mb15'],
                  },
                  {
                    width: '40%',
                    text: totals.debit,
                    style: ['defaultFont', 'textRight', 'mb15'],
                  },
                  {
                    width: '23%',
                    text: totals.credit,
                    style: ['defaultFont', 'textRight', 'mb15'],
                  },
                  {
                    width: '23%',
                    text: totals.return,
                    style: ['defaultFont', 'textRight', 'mb15'],
                  },
                ],
              },
            ],
            styles: {
              defaultFont: {
                fontSize: 11,
                bold: true,
              },
              textRight: {
                alignment: 'right',
              },
              textCenter: {
                alignment: 'center',
              },
              mb2: {
                margin: [0, 0, 0, 2],
              },
              mt2: {
                margin: [0, 4, 0, 0],
              },
              mb15: {
                margin: [0, 3, 0, 2],
              },
            },
          };
        })
        .then(() => {
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          pdfMake.createPdf(documentDefinition.value).open();
          body.value = [];
        });
    };
    watch(
      () => props.counter_all,
      (currentValue, oldValue) => {
        if (currentValue != oldValue) {
          loadPdf();
        }
      }
    );
    return {
      dailyData,
      documentDefinition,
      body,
      loadPdf,
    };
  },
};
</script>

<script>
import { watch, ref, reactive } from 'vue';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import balanceSheetServices from '@/services/BalanceSheetServices';
import moment from 'moment';
export default {
  props: {
    counter_all: Number,
    start_date: String,
    salesman_id:Number,
    end_date: String,

  },
  setup(props) {
    const dailyData = ref([]);
    const Remaining_amount = ref([]);
    const documentDefinition = ref({});
    const body = ref([]);
    const totals=reactive({
      credit:0,
      debit:0,
      return:0,
    })
    const loadPdf = async () => {
      await balanceSheetServices
        .getDailyCheckPDF(props.start_date,props.end_date,props.salesman_id)

        .then((response) => {
          dailyData.value = response.data.data;
          Remaining_amount.value = response.data.remaning.remaining_amount
        })
        .then(() => {
          const columns = [
            { text: 'id', style: 'defaultFont', alignment: 'center' },
            { text: 'Date ', style: 'defaultFont', alignment: 'center' },
            { text: 'Debit', style: 'defaultFont', alignment: 'center' },
            { text: 'Credit', style: 'defaultFont', alignment: 'center' },
            { text: 'Return', style: 'defaultFont', alignment: 'center' },
            { text: 'Remaining ', style: 'defaultFont', alignment: 'center' },

          ];
          body.value.push(columns);
          let i = 1;
          let rem_amount = Remaining_amount.value;

          dailyData.value.forEach(function (row) {
            const dataRow = [];
            const id = {
              text: i.toString(),
              style: 'defaultFont',
              alignment: 'center',
            };

            dataRow.push(id);

            const name = {
              style: 'defaultFont',
              alignment: 'center',
            };

            if (row['first_name'] != '') {
              name['text'] = row['date'];
            } else {
              name['text'] = '';
            }
            dataRow.push(name);

            const debit = {
              text: row['total_debit'].toString(),
              style: 'defaultFont',
              alignment: 'center',
            };
            dataRow.push(debit);

            const credit = {
              text: row['total_credit'].toString(),
              style: 'defaultFont',
              alignment: 'center',
            };

            dataRow.push(credit);

            const back = {
              text: row['total_return'].toString(),
              style: 'defaultFont',
              alignment: 'center',
            };
            dataRow.push(back);
            rem_amount = parseInt(rem_amount) + parseInt(row['total_debit']) - parseInt(row['total_credit']) + parseInt(row['total_return']);

            const remaining = {
              text: rem_amount.toString(),
              style: 'defaultFont',
              alignment: 'center',
            };
            dataRow.push(remaining);

            totals.credit += eval(row['total_credit']);
            totals.debit += eval(row['total_debit']);
            totals.return += eval(row['total_return']);
            i++;
            body.value.push(dataRow);
          });
        })
        .then(() => {
          documentDefinition.value = {
            content: [
              {
                columns: [
                  {
                    width: '50%',
                    text: dailyData.value[0].first_name + ' ' + dailyData.value[0].last_name + ' ' +  'Report',
                    style: ['defaultFont', 'mb2'],
                  },
                  {
                    width: '51%',
                    text: 'Date: ' + moment(String(props.start_date)).format('DD-MM-YYYY') +' to '+ moment(String(props.end_date)).format('DD-MM-YYYY'),
                    style: ['defaultFont', 'textRight', 'mb2'],
                  },
                ],
              },

              {
                  columns: [
                      {
                      width: '38%',
                      text: 'Print Date: ' + moment().format('DD-MM-YYYY'),
                      style: [ 'defaultFont', 'textLeft', 'mb15']
                      },
                  ],
              },

              {
                  columns: [  
                      {
                        width: '95%',
                        text: 'Opening Amount : '+Remaining_amount.value,
                        style: ['defaultFont', 'textRight', 'mb2'],
                      },
                  ],
              },

              {
                table: {
                  headerRows: 1,
                  widths: [30, '*', '*', '*', '*', '*'],
                  body: body.value,
                },
              },
              {
                columns: [
                  {
                    width: '7%',
                    text: 'Total: :',
                    style: ['defaultFont', 'textRight', 'mb15'],
                  },
                  {
                    width: '30%',
                    text: totals.debit,
                    style: ['defaultFont', 'textRight', 'mb15'],
                  },
                  {
                    width: '20%',
                    text: totals.credit,
                    style: ['defaultFont', 'textRight', 'mb15'],
                  },
                  {
                    width: '18%',
                    text: totals.return,
                    style: ['defaultFont', 'textRight', 'mb15'],
                  },
                ],
              },
            ],
            styles: {
              defaultFont: {
                fontSize: 11,
                bold: true,
              },
              textRight: {
                alignment: 'right',
              },
              textCenter: {
                alignment: 'center',
              },
              mb2: {
                margin: [0, 0, 0, 2],
              },
              mt2: {
                margin: [0, 4, 0, 0],
              },
              mb15: {
                margin: [0, 3, 0, 2],
              },
            },
          };
        })
        .then(() => {
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          pdfMake.createPdf(documentDefinition.value).open();
          body.value = [];
        });
    };
    watch(
      () => props.counter_all,
      (currentValue, oldValue) => {
        if (currentValue != oldValue) {
          loadPdf();
        }
      }
    );
    return {
      dailyData,
      documentDefinition,
      body,
      loadPdf,
    };
  },
};
</script>

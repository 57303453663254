<template>
    <!-- modal start -->
  <!-- Edit Modal -->
	<div class="modal fade p-0" id="add" tabindex="-1" aria-labelledby="editLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg">
			<div class="d-flex flex-wrap w-100 position-relative event-auto">
				<div class="card shadow-none">
					<div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
						<div class="content-card-left w-100">
							<h4 class="card-title text-white">Add Shopkeeper</h4>
							<p class="card-category mb-0">Here you can add Shopkeeper</p>
						</div>
					</div>
					<div class="card-body" >
						<Form class="custom-form mt-2" @submit="doSubmit" @keydown="errors_v.clear($event.target.name)" :validation-schema="schema" v-slot="{ errors }">
							<div class="position-relative mb-5">
								<Field type="text" name="shop_name" :class="[form.shop_name=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.shop_name"/>
								<label for="" class="mb-0 placeholder-label">Shop Name</label>
                  <p v-if="errors.shop_name" class="invalid-message mb-0 pt-2">
                      {{errors.shop_name}}
                  </p>
                  <p v-if="errors_v.get('shop_name')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('shop_name')}}
                  </p>
              </div>
              <div class="position-relative mb-5">
								<Field type="text" name="owner_name" :class="[form.owner_name=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.owner_name"/>
								<label for="" class="mb-0 placeholder-label">owner Name</label>
                  <p v-if="errors.owner_name" class="invalid-message mb-0 pt-2">
                      {{errors.owner_name}}
                  </p>
                  <p v-if="errors_v.get('owner_name')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('owner_name')}}
                  </p>
              </div>
              <div class="position-relative mb-5">
								<Field type="text" name="city_name" :class="[form.city_name=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.city_name"/>
								<label for="" class="mb-0 placeholder-label">City</label>
                <p v-if="errors.city_name" class="invalid-message mb-0 pt-2">
                      {{errors.city_name}}
                </p>
                <p v-if="errors_v.get('city')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('city')}}
                  </p>
              </div>

							<div class="row m-0">
								<div class="position-relative mb-5 col-lg-6 col-md-6 col-sm-12 col-12 ps-0 pe-lg-4 pe-md-4 pe-sm-0 pe-0">
									<Field type="text" name="mobile_number" :class="[form.mobile_number=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.mobile_number"/>
									<label for="" class="mb-0 placeholder-label">Mobile Number</label>
                  <p v-if="errors.mobile_number" class="invalid-message mb-0 pt-2">
                      {{errors.mobile_number}}
                  </p>
                  <p v-if="errors_v.get('mobile')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('mobile')}}
                  </p>
                </div>
								<div class="position-relative mb-5 col-lg-6 col-md-6 col-sm-12 col-12 pe-lg-0 pe-0 ps-lg-3 ps-md-3 ps-sm-0 ps-0">
									<Field type="text" name="whatsapp_number" :class="[form.whatsapp_number=='' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.whatsapp_number"/>
									<label for="" class="mb-0 placeholder-label ps-lg-3 ps-md-3 ps-sm-0 ps-0">Whatsapp Number</label>
                  <p v-if="errors.whatsapp_number" class="invalid-message mb-0 pt-2">
                      {{errors.whatsapp_number}}
                  </p>
                  <p v-if="errors_v.get('whats_app')" class="invalid-message mb-0 pt-2">
                      {{errors_v.get('whats_app')}}
                  </p>
                </div>
							</div>
							<div class="position-relative">
								<textarea name="address" :class="[form.address=='' ? '' : 'active', 'border-0 position-relative w-100 resize-none']" v-model="form.address" rows="3"></textarea>
								<label for="" class="mb-0 placeholder-label">Address</label>
                
              </div>
							<div class="card-footer px-4 pb-0 border-0 bg-transparent mb-3">
                <button class="btn btn-primary w-100" :disabled="searchDisabled">
                  <div v-if="loader" class="spinner-border me-2" role="status"></div>Add
                </button>
					</div>
						</Form>
					</div>
					
				</div>
			</div>
		</div>			
	</div>
  <!-- modal end -->
  
</template>

<script>
    import {ref, reactive } from 'vue';
    import Errors from "@/utils/Errors";
    import { Form, Field,  ValidationObserver  } from 'vee-validate';
    import * as Yup from 'yup'; 
    import ShopServices from '@/services/ShopServices';
    
export default{
    components:{
            Form,
            Field
        },
    
    setup(props,{emit}){
        const loader=ref(false);
        const searchDisabled=ref(false);
        
        const initialState = {
          shop_name: "",
          owner_name: "",
          city_name: "",
          mobile_number: "",
          whatsapp_number: "",
          address: ""
        };
        
        const form = reactive({ ...initialState });

        const schema = Yup.object().shape({
            shop_name: Yup.string()
                .required('Shop Name is required'),
            owner_name: Yup.string()
                .required('Owner Name is required'),
            city_name: Yup.string()
                .required('City Name is required'),
            mobile_number: Yup.number()
                .typeError("Only Numbers Allowed"),
            whatsapp_number: Yup.number()
                .typeError("Only Numbers Allowed")
        });
      
        const errors_v = reactive(new Errors());
        function resetForm() {
            Object.assign(form, initialState);
          }
        const doSubmit = async(values, actions) => {
          loader.value=true;
          searchDisabled.value=true;
          const payload = {
            shop_name: form.shop_name,
            owner_name: form.owner_name,
            city: form.city_name,
            mobile: form.mobile_number,
            whats_app: form.whatsapp_number,
            address: form.address
          };
          


          await ShopServices.insertShop(payload)
              .then(async response=>{
                
                const myModalEl = document.getElementById('add');
                const modal = window.bootstrap.Modal.getInstance(myModalEl);
                modal.hide();
                resetForm();
                emit('alertEvent', 'add');
                loader.value=false;
                searchDisabled.value=false;
                //this.$refs.obs.reset();
                actions.resetForm()
                
                
              })
              .catch(error => {
                loader.value=false;
                searchDisabled.value=false;
                if(error.response.status==422){
                  errors_v.record(error.response.data.errors);
                }
              });
        }
        return {
            schema,
            form,
            doSubmit,
            errors_v,
            resetForm,
            loader,
            searchDisabled            

        }
    }
}
</script>

<script>
import { watch, ref } from 'vue';
//import  jsPDF  from "jspdf";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import SalesmanShopServices from '@/services/SalesmanShopServices';
import moment from 'moment';
//import htmlToPdfmake from 'html-to-pdfmake';
export default {
    props:{
        salesman_id: String,
        salesman_name: String,
        counter_all: Number
    },
    setup(props){
        const totalBalance=ref(0);
        const pdfData=ref([]);
        const documentDefinition=ref({});
        const body=ref([]);
        const loadPdf=async ()=>{
            await SalesmanShopServices.getAllPdfContent(props.salesman_id)
      			.then(response=>{	
                    pdfData.value=response.data.data;
      			})
                .then(() => {
                    totalBalance.value=0;
                    const columns=[
                        {text: 'No', style: 'defaultFont', alignment: 'center'},
				        {text: 'Name ', style: 'defaultFont', alignment: 'center'},
                        {text: 'Address', style: 'defaultFont', alignment: 'center'},
				        {text: 'Day', style: 'defaultFont', alignment: 'center'},
				        {text: 'Balance', style: 'defaultFont', alignment: 'center'}
                    ];
                    body.value.push(columns);
                    let i=1;
                    pdfData.value.forEach(function(row) {
                        const dataRow=[];
                        const seriol_no={
                            text: i.toString(),
                            style: 'defaultFont',
                            alignment: 'center'
                        };
                        dataRow.push(seriol_no);
                        
                        
                        const shop_name={
                            style: 'defaultFont',
                            alignment: 'center'
                        };
                        
                        if(row['shop_name']!="" ){
                            shop_name['text']=row['shop_name'].toString();
                            //shop_name['text']="a";
                        }
                        else{
                            shop_name['text']="";
                            
                        }
                        dataRow.push(shop_name);

                        const address={
                            style: 'defaultFont',
                            alignment: 'center'
                        };
                        
                        if(row['address']){
                            address['text']=row['address'].toString();
                        }
                        else{
                            address['text']="";
                            
                        }
                        dataRow.push(address);
                        
                        i++;
                        

                        const day={
                            text: row['day'].toString(),
                            style: 'defaultFont',
                            alignment: 'center'
                        };
                        dataRow.push(day);
                        
                        const total={
                            text: row['currentBalance'].toString(),
                            style: 'defaultFont',
                            alignment: 'center'
                        };
                        dataRow.push(total);
                        if(row['currentBalance']!=""){
                            
                            totalBalance.value=eval(totalBalance.value)+eval(row['currentBalance'])
                        
                        }
                        
                        body.value.push(dataRow);
                    });
                   
                })
                .then(() => {
                    documentDefinition.value = {
                        content: [
                            
                            {
                                columns: [
                                    {
                                    width: '50%',
                                    text: 'SALESMAN:'+props.salesman_name+" ("+props.salesman_id+")",
                                    style: [ 'defaultFont', 'mb2' ]

                                    }
                                ],
                                
                            },
                            {
                                table: {
                                    headerRows: 1,
                                    widths: [30, '*', '*', '*', '*'],
                                    body: body.value
                                }
                            },
                            {
                            columns: [
                                
                                {
                                    width: 135,
                                    text: 'Total',
                                    style: [ 'defaultFont', 'textRight', 'mt2']
                                },
                                
                                {
                                width: 610,
                                text: totalBalance.value.toFixed(2),
                                style: [ 'defaultFont', 'textCenter', 'mt2']
                                },
                                

                            ],
                            
                            columnGap: 2,
                            
                            },
                        ],
                        styles: {
                            defaultFont: {
                            fontSize: 11,
                            bold: true
                            },
                            textRight: {
                            alignment: 'right'
                            },
                            textCenter: {
                            alignment: 'center'
                            },
                            mb2: {
                            margin:[0,0,0,2]
                            },
                            mt2: {
			                    margin:[0,4,0,0]
		                    },

                        }
                        };
                })
                .then(() => {
                    pdfMake.vfs = pdfFonts.pdfMake.vfs;
                    pdfMake.createPdf(documentDefinition.value).open();
                    body.value=[];
                });

                
        }
        watch( ()=>props.counter_all, (currentValue, oldValue) => {
			if(currentValue!=oldValue && currentValue!=""){
                loadPdf();
			}
        });
        
        return {
            pdfData,
            totalBalance,
            documentDefinition,
            body
        }
    }
}
</script>

<template>
  <div class="inner-content">
    <section>
      <div class="container-fluid">
        <div class="row m-0">
          <div class="col-12">
            <div class="card">
              <div
                class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
              >
                <div class="content-card-left">
                  <h4 class="card-title text-white">Shopkeepers</h4>
                  <p class="card-category mb-0">
                    Here you can manage shopkeeper's data
                  </p>
                </div>
                <div class="content-card-right ms-auto">
                  <a
                    href="#edit"
                    data-bs-toggle="modal"
                    data-bs-target="#add"
                    @click.prevent
                    class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle"
                  >
                    <i class="bi bi-plus-square-fill text-white"></i>
                  </a>
                </div>
              </div>
              <div class="card-body">
                <div class="header-content">
                  <form
                    class="table-header mx-0 mb-1 custom-form position-sticky"
                  >
                    <div class="row m-0 align-items-center">
                      <div
                        class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 px-0"
                      >
                        <h4 class="filter mb-lg-0 mb-md-0 mb-3">
                          <i class="bi bi-funnel me-1 position-relative"></i
                          >Filters
                        </h4>
                      </div>
                      <div
                        class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 px-0 ms-lg-auto ms-md-auto"
                      >
                        <div class="position-relative w-100">
                          <Multiselect v-model="searchData.shopId"
                          :options="search_shops" class="custom-multiselect
                          table-header-multi h-auto" placeholder="Search Shop"
                          :searchable="true" :showOptions="true" mode="single"
                          label="shopName" trackBy = "shop_name" valueProp="id"
                          required: true />

                          <i
                            class="bi bi-search position-absolute search-multi"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div class="table-responsive mb-0">
                    <table id="my-table" class="custom-table shop-table">
                      <thead>
                        <tr>
                          <th class="text-capitalize">Id</th>
                          <th class="text-capitalize">Shop Name</th>
                          <th class="text-capitalize">Name</th>
                          <th class="text-capitalize">Mobile</th>
                          <th class="text-capitalize">Whatsapp</th>
                          <th class="text-capitalize">City</th>
                          <th class="text-capitalize">Address</th>
                          <th class="text-capitalize text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="shop in shops" :key="shop.id">
                          <td>{{ shop.id }}</td>
                          <td>{{ shop.shop_name }}</td>
                          <td>{{ shop.owner_name }}</td>
                          <td v-if="shop.mobile">{{ shop.mobile }}</td>
                          <td v-else>---</td>

                          <td v-if="shop.whats_app">{{ shop.whats_app }}</td>
                          <td v-else>---</td>
                          <td>{{ shop.city }}</td>
                          <td>{{ shop.address }}</td>
                          <td class="action text-center">
                            <a
                              href="#"
                              class="edit d-inline-block"
                              data-bs-toggle="modal"
                              data-bs-target="#edit"
                              @click="edit_shop_id = shop.id"
                            >
                              <i class="bi bi-pencil-fill"></i>
                            </a>
                            <a
                              href="#"
                              class="edit d-inline-block text-center"
                              @click="shop_id_pdf = shop.id,Pdfcounter++"
                            >
                              <img
                                src="@/assets/images/pdf.png"
                                alt="pdf-image"
                                class="small-image"
                              />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent"
              >
                <p class="d-lg-inline-block table-data">
                  Showing
                  {{ current_page * entries_per_page - (entries_per_page - 1) }}
                  to
                  {{
                    current_page * entries_per_page -
                    (entries_per_page - shops.length)
                  }}
                  of {{ total_count }} entries.
                </p>

                <!--<pagination :v-model="page" :records="44" :per-page="15" @paginate="myCallback"/>-->
                <div
                  class="d-inline-block ms-auto me-lg-0 me-md-0 me-sm-0 me-auto mb-lg-0 mb-md-0 mb-sm-0 mb-4 pagination-wrapper"
                >
                  <v-pagination
                    class="d-flex flex-lg-wrap flex-md-wrap flex-sm-wrap align-items-center p-0 m-0"
                    v-model="current_page"
                    :pages="total_pages"
                    :range-size="3"
                    @update:modelValue="loadData"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <shopkeeper-add-modal @alert-event="showGrowl"></shopkeeper-add-modal>
  <shopkeeper-edit-modal
    @alert-event="showGrowl"
    :shop_id="edit_shop_id"
  ></shopkeeper-edit-modal>
  <shopkeeper-salesman-pdf :shop_id="shop_id_pdf" :Pdfcounter="Pdfcounter" @alert-event="showGrowl"></shopkeeper-salesman-pdf>
  <Alert
    v-if="growlMessage"
    :name="alert_m.name"
    :alertHeading="alert_m.heading"
    :alertDescription="alert_m.description"
  >
  </Alert>
</template>

<script>
import '@/assets/scss/pages/shop.scss';
import shopServices from '@/services/ShopServices';
import { onMounted, ref, reactive, watch } from 'vue';
import Alert from '@/components/alert';
//import pagination from '@/components/pagination';
import VPagination from '@hennge/vue3-pagination';
//import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import ShopkeeperAddModal from '@/components/modals/ShopkeeperAddModal.vue';
import ShopkeeperEditModal from '@/components/modals/ShopkeeperEditModal.vue';
// Add this for multiselect
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
import ShopkeeperSalesmanPdf from '@/components/pdf/ShopkeeperSalesmanPdf';
export default {
  name: 'Shopkeeper',
  components: {
    VPagination,
    ShopkeeperAddModal,
    ShopkeeperEditModal,
    Multiselect,
    Alert,
    ShopkeeperSalesmanPdf,
  },
  setup() {
    const total_count = ref(0);
    const entries_per_page = ref(0);
    const search_shops = ref([]);
    const shops = ref([]);
    const total_pages = ref(0);
    const current_page = ref(1);
    const edit_shop_id = ref(0);
    const shop_id_pdf=ref(0);
    const Pdfcounter=ref(0);
    const alert_m = reactive({
      name: '',
      heading: '',
      description: '',
    });
    const searchData = reactive({
      shopId: '',
      salesmanId: '',
    });

    const growlMessage = ref(false);
    const loadData = async () => {
      await shopServices
        .paginateShops(current_page.value, searchData)
        .then((response) => {
          shops.value = response.data.data;
          total_pages.value = response.data.last_page;
          current_page.value = response.data.current_page;
          entries_per_page.value = 15;
          total_count.value = response.data.total;
        })
        .catch((error) => {
          alert('error');
        });
    };
    const loadShops = async () => {
      await shopServices.getAllShops().then((response) => {
        search_shops.value = response.data;
      });
    };
    onMounted(() => {
      loadData();
      loadShops();
    });
    watch([searchData], ([newSearchData], [prevSearchData]) => {
      if (newSearchData.shopId == null) {
        newSearchData.shopId = '';
      }
      loadData();
    });

    const showGrowl = (alertType) => {
      if (alertType == 'add') {
        alert_m.name = 'success';
        alert_m.description = 'Record Added Successfully';
      } else if (alertType == 'edit') {
        alert_m.name = 'success';
        alert_m.description = 'Record Updated Successfully';
      } else {
        alert_m.name = 'danger';
        alert_m.description = 'No Record Found. Please try with valid data';
      }

      alert_m.heading = 'Shopkeeper';
      growlMessage.value = true;
      setTimeout(() => {
        growlMessage.value = false;
      }, 5000);
    };

    return {
      shops,
      total_pages,
      current_page,
      loadData,
      edit_shop_id,
      showGrowl,
      alert_m,
      growlMessage,
      entries_per_page,
      searchData,
      search_shops,
      total_count,
      Pdfcounter,
      shop_id_pdf,
    };
  },
};
</script>

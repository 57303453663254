<template>
    <div class="inner-content">
        <section>
            <div class="container-fluid">
                <div class="row m-0">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative py-4">
                <div class="content-card-left">
                  <h4 class="card-title text-white">{{$route.query.companyName}} TRANSACTIONS</h4>
                </div>
                 <div class="content-card-right ms-auto d-flex flex-wrap">
                  <a
                   href="#addtransaction" data-bs-toggle="modal" data-bs-target="#addtransaction" @click.prevent class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle">
												<i class="bi bi-plus-square-fill text-white"></i>
											</a>
                </div>
                        </div>
                            <div class="card-body pt-0" >
                                <div class="header-content">
                                  <form class="table-header mx-0 mb-4 custom-form">
                                <div class="row m-0 align-items-center">
                                  <div class="col-lg-3 col-md-3 col-sm-3 col-12 px-0">
                                    <h4 class="filter mb-lg-0 mb-md-0 mb-sm-0 mb-3">
                                      <i class="bi bi-funnel me-1 position-relative"></i
                                      >Filters
                                    </h4>
                                  </div>
                                  <div
                                    class="col-lg-8 col-md-8 col-sm-9 col-12 px-0 ms-auto"
                                  >
                                    <v-date-picker
                                      class="custom-calendar left-caret-none"
                                      popover-align="right"
                                      :step="1"
                                      v-model="range"
                                      is-range
                                      :columns="$screens({ default: 2, lg: 2 })"
                                    >
                                      <template v-slot="{ inputValue, inputEvents }">
                                        <div
                                          class="d-flex flex-wrap align-items-center justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-start"
                                        >
                                          <div
                                            class="position-relative calendar-input-wrapper clearfix"
                                          >
                                            <span class="d-inline-block me-4 float-left"
                                              >From</span
                                            >
                                            <i
                                              class="bi bi-calendar3 position-absolute icon-left-placement"
                                            ></i>
                                            <input
                                              :value="inputValue.start"
                                              v-on="inputEvents.start"
                                              class="border-0 icon-left position-relative float-left"
                                            />
                                          </div>
                                          <div
                                            class="position-relative calendar-input-wrapper clearfix"
                                          >
                                            <span
                                              class="d-inline-block ms-lg-4 ms-md-4 ms-sm-4 ms-0 me-lg-0 me-md-0 me-sm-0 me-4 float-left"
                                              >To</span
                                            >
                                            <i
                                              class="bi bi-calendar3 position-absolute icon-left-placement"
                                            ></i>
                                            <input
                                              :value="inputValue.end"
                                              v-on="inputEvents.end"
                                              class="border-0 icon-left position-relative float-left"
                                            />
                                          </div>
                                        </div>
                                      </template>
                                    </v-date-picker>
                                  </div>
                                </div>
                              </form>
                            <div class="table-responsive mb-0">
                                <table id="my-table"  class="custom-table w-100">
                                    <thead>
                                        <tr>
                                            <th class="text-capitalize">Id</th>
                                            <th class="text-capitalize">Date</th>
                                            <th class="text-capitalize">Debit</th>
                                            <th class="text-capitalize">Credit</th>
                                            <th class="text-capitalize">Amount</th>
                                            <th class="text-capitalize">Remaining</th>
                                            <th class="text-capitalize">Description</th>
                                            <th class="text-capitalize">Link</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="transaction in transactions" :key="transaction.id" >
                                                    <td>{{transaction.id}}</td>
													            <td>{{formatDate(transaction.payment_at)}}</td>
													            <td v-if="transaction.type==='debit'" >{{transaction.type}}</td>
                                                                <td v-else>---</td>
														        <td v-if="transaction.type==='credit'">{{transaction.type}}</td>
                                                                <td v-else>---</td>
                                                                <td>{{transaction.amount}}</td>
														        <td>{{transaction.remaining_amount}}</td>
														        <td v-if="transaction.description">{{transaction.description}}</td>
                                                                <td v-else>---</td>
                                                                <td v-if="transaction.file_uploaded">
                                                                <a :href="getFileUrl(transaction.file_uploaded)" target="_blank">
                                                                  Show Image
                                                                </a>
                                                                </td>
                                                                <td v-else>---</td>
												        </tr>
                                             </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent">
                <p class="d-lg-inline-block table-data">
                  Showing
                  {{ current_page * entries_per_page - (entries_per_page - 1) }}
                  to
                  {{
                    current_page * entries_per_page -
                    (entries_per_page - transactions.length)
                  }}
                  of {{ total_count }} entries.
                </p>
                
                <!--<pagination :v-model="page" :records="44" :per-page="15" @paginate="myCallback"/>-->
                <div class="d-inline-block ms-auto me-lg-0 me-md-0 me-sm-0 me-auto mb-lg-0 mb-md-0 mb-sm-0 mb-4 pagination-wrapper">
                <v-pagination class="d-flex flex-lg-wrap flex-md-wrap flex-sm-wrap align-items-center p-0 m-0"
                  v-model="current_page"
                  :pages="total_pages"
                  :range-size="3"
                  
                  @update:modelValue="loadtransactions"
                />
                </div>
              </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

	<Transaction-Modal-Vue :company_id="company_id"  @load-transactions="loadtransactions"  @alert-event="showGrowl"></Transaction-Modal-Vue>

    <Alert
      v-if="growlMessage"
      :name="alert_m.name"
      :alertHeading="alert_m.heading"
      :alertDescription="alert_m.description">
  </Alert>
</template>

<script>
import {onMounted, ref,reactive, watch,computed} from 'vue';
import TransactionModalVue from '@/components/modals/TransactionModal.vue';
import companybalance from '@/services/CompanyBalanceService';
import VPagination from "@hennge/vue3-pagination";
import Alert from "@/components/alert";
import { useRouter } from 'vue-router';
import moment from 'moment';
import { useStore } from 'vuex';
export default {
    props: {
    		company_id: Number,
  		},
    components:{
        TransactionModalVue,
        VPagination,
        Alert,
    },
    
    setup(props) {
        const transactions =ref([]);
        const current_page= ref(1);
        const entries_per_page=ref(0);
        const total_count=ref(0);
        const total_pages= ref(0);
        const router= useRouter();
        const store = useStore();
        const startDate = ref('');
        const endDate = ref('');
        const alert_m = reactive({
				name: '',
				heading: '',
				description: ''
			});
      const range = ref({
				start: moment().format('MM-DD-YYYY'),
        end: new Date(new Date().getFullYear(), new Date().getMonth()
, 1),
    });
        const growlMessage = ref(false);
        
        const getFileUrl = (filePath) => {
      return process.env.VUE_APP_API_BASE_URL +'/storage/'+ filePath;
    };
		const showGrowl = (alertType) =>{
			if(alertType=="add"){
				alert_m.name="success";
				alert_m.description="Record Added Successfully";
			}
			else if(alertType=="edit"){
				alert_m.name="success";
				alert_m.description="Record Updated Successfully";
			}
			else{
				alert_m.name="danger";
				alert_m.description="No Record Found. Please try with valid data";
			}
    
    
			alert_m.heading="Transaction";
			growlMessage.value=true;
			setTimeout(() => { growlMessage.value=false; }, 5000)
    	}
    
            const loadtransactions= async()=>{
      			await companybalance.getTransactions(props.company_id,current_page.value, startDate.value, endDate.value)
      			.then(response=>{
                   
        			transactions.value= response.data.data;
					total_pages.value=response.data.last_page;
        			current_page.value=response.data.current_page;
					entries_per_page.value=response.data.per_page;
					total_count.value=response.data.total;
      			});
    		}
            
            onMounted( () => {
				loadtransactions();      
			});

            watch(async()=>total_count.value, (currentValue, oldValue) => {
          if(currentValue!=oldValue && currentValue!=""){
            loadtransactions();
             
          }
        
            });
            watch(
      async () => range.value,
      (currentValue, oldValue) => {
        if (currentValue != oldValue && currentValue != '') {
          startDate.value = moment(String(range.value.start)).format(
            'YYYY-MM-DD'
          );
          endDate.value = moment(String(range.value.end)).format('YYYY-MM-DD');

          loadtransactions();
        }
      }
    );
        
            const formatDate = (value) => {
      if (value) {
        return moment(String(value)).format('MMM Do YY');
      }
    };


            return {
                transactions,
                current_page,
                entries_per_page,
				total_count,
                total_pages,
                alert_m,
                formatDate,
				growlMessage,
				showGrowl,
                getFileUrl,
                loadtransactions,
                range
                

            }
        }}

</script>
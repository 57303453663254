<template>
  <!-- modal start -->
  <div class="modal fade p-0" id="addtransaction" tabindex="-1" aria-labelledby="editLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="d-flex flex-wrap w-100 position-relative event-auto">
        <div class="card shadow-none">
          <Form class="custom-form mt-2" @submit="doSubmit" @keydown="errors_v.clear($event.target.name)" :validation-schema="schema" v-slot="{ errors }">
            <div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
              <div class="content-card-left w-100">
                <h4 class="card-title text-white fs-4">Add Balance</h4>
                <p class="fw-light text-white mb-0 pt-1">Create a New Company-Balance</p>
              </div>
            </div>
            <div class="card-body">
              <div class="position-relative mb-5">
                <Field type="date" name="date" :class="[form.date === '' ? '' : 'active', 'border-0 position-relative w-100']" v-model="form.date" />
                <label for="" class="mb-0 placeholder-label">Date</label>
                <p v-if="errors.date" class="invalid-message mb-0 pt-2">{{ errors.date }}</p>
                <p v-if="errors_v.get('Amount')" class="invalid-message mb-0 pt-2">{{ errors_v.get('Amount') }}</p>
              </div>

              <div class="position-relative mb-5">
                <Field
                  type="number"
                  name="amount"
                  :class="[form.amount === '' ? '' : 'active', 'border-0 position-relative w-100']"
                  v-model="form.amount"
                />
                <label for="" class="mb-0 placeholder-label">Amount</label>
                <p v-if="errors.amount" class="invalid-message mb-0 pt-2">{{ errors.amount }}</p>
                <p v-if="errors_v.get('Amount')" class="invalid-message mb-0 pt-2">{{ errors_v.get('Amount') }}</p>
              </div>

              <div class="position-relative mb-5">
                <Field
                  type="text"
                  name="description"
                  :class="[form.description === '' ? '' : 'active', 'border-0 position-relative w-100 resize-none']"
                  v-model="form.description"
                />
                <label for="" class="mb-0 placeholder-label">Description</label>
                <p v-if="errors.description" class="invalid-message mb-0 pt-2">{{ errors.description }}</p>
                <p v-if="errors_v.get('description')" class="invalid-message mb-0 pt-2">{{ errors_v.get('description') }}</p>
              </div>

              <div class="position-relative mb-5">
                <label for="" class="d-block mb-2 placeholder-label position-static transform-none">Upload</label>
                <input type="file" name="cnic_front" id="upload_front" accept="image/*" @change="onFileChange" />
              </div>
            </div>

            <div class="card-footer px-4 pb-0 border-0 bg-transparent mb-3">
              <button class="btn btn-primary w-100" >
                <div v-if="loader" class="spinner-border me-2" role="status"></div>Add Balance
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>

  </div>
  <!-- modal end -->
</template>

<script>
import { ref, reactive} from 'vue';
import Errors from '@/utils/Errors';
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import CompanyBalance from '@/services/CompanyBalanceService';
import { useRouter } from 'vue-router';

export default {
  props: {
    company_id: Number,
    current_page:Number,
  },
  components: {
    Form,
    Field
  },
 
  setup(props,context) {
    const loader = ref(false);
    const router= useRouter();
    const company_id = ref(props.company_id);
   
    const form = reactive({
      date:new Date().toISOString().slice(0,10),
      amount: '',
      description: '',
      file: '' });

    const schema = Yup.object().shape({
      amount: Yup.number().required('Amount is required'),
      description: Yup.string().required('Description is required'),
      date: Yup.date().required('Date is required')
    });

    const errors_v = reactive(new Errors());

    const resetForm = () => {
      Object.assign(form);
    };

    const onFileChange = (event) => {
      const file = event.target.files[0];
      form.file = file;
    };

    const doSubmit = async (values, actions) => {
      loader.value = true;
      const formData = new FormData();
      formData.append('amount', form.amount);
      formData.append('description', form.description);
      formData.append('date', form.date);
      formData.append('company_id', company_id.value);
      formData.append('file', form.file);
      await CompanyBalance.insertBalance(formData)
        .then(async (response) => {
          context.emit('load-transactions');
          const myModalEl = document.getElementById('addtransaction');
          const modal = window.bootstrap.Modal.getInstance(myModalEl);
          modal.hide();
          loader.value = false;
          actions.resetForm();
          form.file='';
         
         
          // router.push('/companies/transactions/'+company_id.value);
        })
        .catch((error) => {
          loader.value = false;
          if (error.response.status === 422) {
            errors_v.record(error.response.data.errors);
          }
        });
    };

    return {
      schema,
      form,
      doSubmit,
      errors_v,
      resetForm,
      loader,
      onFileChange,
      
    };
  }
};
</script>

